// aos-wrapper.js
import AOS from 'aos';
import 'aos/dist/aos.css';

const AOSPlugin = {
  install: function (app) {
    app.config.globalProperties.$aos = AOS;
    app.config.globalProperties.$aos.init();
  }
};
export default AOSPlugin;