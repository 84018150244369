<template>
    <div class="container mt-3">
        <DataLoader>
            <template v-slot:default="{ model }">
                <div v-if="model">
                    <AllBets :model="model" :username-slug="userParam"/>
                    <div class="mt-5">
                        <a href="/rangliste" class="btn btn-sm btn-primary">Zur Rangliste</a>  <a href="/mitmachen" class="btn btn-sm btn-primary ms-3">Mitmachen</a>
                    </div>
                </div>
            </template>
        </DataLoader>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';
import DataLoader from "../components/DataLoader.vue";
import AllBets from "../components/AllBets.vue";
//import { toast } from 'vue3-toastify';
export default {
    components: { DataLoader, AllBets },
    setup(){
        const route = useRoute();
        let userParam = route.params.userParameter;
        if(userParam)
            userParam = userParam.toLowerCase();
        return {userParam};
    },
    async mounted() {     
        //toast.info("Mounted -> Test ");
        //userParameter
    }
}
</script>

<style></style>