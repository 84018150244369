<template>
    <div class="accordion mt-4" id="accordionExample" v-if="season && season.organizations">
      <div v-for="(org, index) in season.organizations" :key="index" class="accordion-item">
        <h2 :id="'heading' + index" class="accordion-header">
          <button class="accordion-button collapsed" type="button" :data-bs-toggle="'collapse'"
                  :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
            {{ org.name }}
          </button>
        </h2>
        <div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index"
             data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <span v-html="org.description"></span>
            <div class="mt-2">
              <a class="bold" :href="org.url">Zur Webseite der Organisation</a>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DonationTargetAccordion',
    props: {
      season: {
        type: Object,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add any component-specific styles here */
  </style>
  