<template>
  <div v-if="loading" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-else-if="error">Daten konnten nicht geladen werden - Fehler: {{ error }}</div>
  <div v-else>
    <slot :model="model"></slot>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useBackendStore } from '../store/useBackendStore';

export default {
  name: 'DataLoader', // Ensure the name is consistent
  setup() {
    const modelStore = useBackendStore();

    onMounted(async () => {
      await modelStore.fetchModel();
      //console.log(modelStore.model);
    });

    const model = computed(() => modelStore.model);
    const loading = computed(() => modelStore.loading);
    const error = computed(() => modelStore.error);


    return {
      model,
      loading,
      error,
    };
  },
};
</script>
