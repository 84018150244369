<template>
    <div class="container mt-3">
        <h1 class="text-center">Spendenhistorie</h1>
        <div class="mt-4">
            Die Saisonspenden wurden 2022 von der Hertha BASE Discord-Community ins Leben gerufen und finden seitdem
            jährlich statt. Jedes Jahr wird die erzielte Spendensumme an andere Organisationen gespendet. Hier erfährst
            du, wie viel Geld pro Jahr zusammengekommen ist und an welche Organisationen es gespendet wurde.
            <br><br>
            <h5>Spendensumme insgesamt: <CountUpDecimal :targetValue="totalDonationAmount" :waitFor=0
                    class="text-success bold" suffix="€"></CountUpDecimal>
            </h5>
        </div>

        <div class="row">
            <div class="col-12 my-4">
                <h2>Beendete Spendenaktionen</h2>
            </div>
            <div v-for="(season) in seasons.filter(q => !q.isCurrentSeason)" :key="season.seasonName"
                class="col-lg-6 mb-5">

                <CardComponent :headline="season.seasonName" :subheadline="season.seasonTimeframe">
                    <br>
                    <b>Erzielte Spendensumme: </b>
                    <CountUpDecimal :targetValue="season.donationAmount" :waitFor=0 class="text-success bold"
                        suffix="€"></CountUpDecimal>
                    <br>
                    <b>Teilnehmende: <span class="text-success">{{ season.donatorsCnt }}</span></b>
                    <br>
                    <b>Eingereichte Wetten: <span class="text-success">{{ season.betsCnt }}</span></b>
                    <div v-html="season.txtHtml" class="my-3"></div>

                    <div v-for="(org, index) in season.organizations" :key="org.name">
                        <div class="d-flex">
                            <a v-if='org.description' href='javascript:void(0)'
                                @click="org.showDescription = true"><font-awesome-icon icon="info-circle"
                                    class="c-blue mt-1" style="margin-right:12px;"></font-awesome-icon></a>
                            <span v-else class="mx-3"></span>
                            <!--<button type="button" class="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-placement="top" data-bs-content=""></button>-->
                            <a v-if='!org.description' :href="org.url" target="_blank">{{ org.name }}</a>
                            <a v-else href='javascript:void(0)' @click="org.showDescription = true">{{ org.name }}</a>
                            <span v-if="org.subName">&nbsp;({{ org.subName }})</span>
                            <span class="ms-auto text-nowrap" v-if="org.images">
                                <font-awesome-icon icon="image" class="fa-image"></font-awesome-icon>&nbsp;<a href="#"
                                    data-bs-toggle="modal" :data-bs-target="`#${org.modalId}`">Spendenbeleg</a>
                            </span>
                        </div>
                        <template v-if="org.showDescription">
                            <div v-if="org.showDescription" v-html="org.description" class="mt-3"></div>
                            <div class="mt-3 bold"><a :href="org.url" target="_blank">Zur Webseite der Organisation</a>
                            </div>
                        </template>
                        <hr v-if="index !== season.organizations.length - 1">
                    </div>

                    <div v-for="org in season.organizations" :key="org.modalId">
                        <div v-if="org.images" class="modal fade" :id="org.modalId" tabindex="-1"
                            :aria-labelledby="`${org.modalId}Label`" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" :id="`${org.modalId}Label`">Spendenbeleg - {{ org.name
                                            }}</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div id="carouselExampleIndicators" class="carousel slide"
                                            data-bs-ride="carousel">
                                            <div class="carousel-indicators" v-if="org.images.length > 1">
                                                <button v-for="(image, index) in org.images" :key="index" type="button"
                                                    :data-bs-target="`#${org.modalId}`" :data-bs-slide-to="index"
                                                    :class="{ 'active': index === 0 }" aria-current="true"
                                                    :aria-label="'Slide ' + (index + 1)"></button>
                                            </div>
                                            <div class="carousel-inner">
                                                <div v-for="(image, index) in org.images" :key="index"
                                                    :class="['carousel-item', { 'active': index === 0 }]">
                                                    <img :src="image" class="d-block w-100"
                                                        :alt="`Spendenbeleg - ${org.name} - Bild ${index + 1}`">
                                                </div>
                                            </div>
                                            <button v-if="org.images.length > 1" class="carousel-control-prev"
                                                type="button" data-bs-target="#carouselExampleIndicators"
                                                data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button v-if="org.images.length > 1" class="carousel-control-next"
                                                type="button" data-bs-target="#carouselExampleIndicators"
                                                data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardComponent>
            </div>
        </div>

        <div v-if="currentSeason">
            <h2>Spendenziele der {{ currentSeason.seasonName }}</h2>
            <br>
            Derzeit wird für die folgenden Organisationen gesammelt. Du kannst jederzeit einsteigen und <a
                href="/mitmachen">mitmachen</a>.
            <br>
            <div class="row" v-if="currentSeason">
                <div class="col-lg-6 offset-lg-3">
                    <DonationTargetAccordion :season="currentSeason"></DonationTargetAccordion>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { defineComponent } from 'vue';
import CardComponent from "../components/core/CardComponent.vue";
import CountUpDecimal from "../components/core/CountUpDecimal.vue";
import seasons from '../assets/seasons.json'; // Import the JSON file
import DonationTargetAccordion from "../components/core/DonationTargetAccordion.vue";


export default defineComponent({
    components: { CardComponent, CountUpDecimal, DonationTargetAccordion },
    data() {
        return {
            seasons,
            currentSeason: seasons.filter(q => q.isCurrentSeason)[0]
        }
    },
    computed: {
        totalDonationAmount() {
            return this.seasons.filter(q => !q.isCurrentSeason).reduce((sum, season) => sum + (season.donationAmount || 0), 0);
        }
    }
});
</script>
