export function GetBetsByFilteredData(model, nameFilter = null) {
    let allBets = model.AllBets;
    //console.log('allBets unfiltered: ', allBets);
    // Apply name filter if provided
    if (nameFilter)
        allBets = allBets.filter(bet => bet.Slug.toLowerCase() === nameFilter.toLowerCase());
   

    //console.log('allBets: ', allBets);

    const groupsByBetTypeId = allBets.reduce((acc, bet) => {
        if (!acc[bet.BetTypeId]) {
            acc[bet.BetTypeId] = [];
        }
        acc[bet.BetTypeId].push(bet);
        return acc;
    }, {});

    const vm = [];
    for (const [betTypeId, bets] of Object.entries(groupsByBetTypeId)) {
        const summaryByBetType = model.SummaryByBetType.find(q => q.BetTypeId == betTypeId);

        summaryByBetType.BetCnt = bets.length;
        summaryByBetType.TotalDonation = bets.reduce((sum, q) => sum + q.TotalBetAmount, 0);
        //summaryByBetType.TotalDonation = 

        const x = {
            BetTypeId: betTypeId,
            SummaryByBetType: summaryByBetType,
            Bets: []
        };

        // Group by BetStr within each BetTypeId group
        const groupsByBetStr = bets.reduce((acc, bet) => {
            if (!acc[bet.BetStr]) {
                acc[bet.BetStr] = [];
            }
            acc[bet.BetStr].push(bet);
            return acc;
        }, {});

        for (const [betStr, betsByStr] of Object.entries(groupsByBetStr)) {
            const totalBetAmount = betsByStr.reduce((sum, bet) => sum + bet.TotalBetAmount, 0);
            const betCnt = betsByStr.length;
            const occured = betsByStr[0].Occured; // Assuming Occured is the same for all bets in this group
            const bet = {
                BetName: betStr,
                TotalBetAmount: totalBetAmount,
                BetCnt: betCnt,
                BetsByUsers: [],
                Occured: occured
            };

            for (const bbu of betsByStr) {
                const betByUser = {
                    Username: bbu.Username,
                    AmountPerOccurence: bbu.BetPerOccurrence,
                    TotalBetAmount: bbu.TotalBetAmount,
                    Slug: bbu.Slug
                };
                bet.BetsByUsers.push(betByUser);
            }

            // Sort BetsByUsers by TotalBetAmount in descending order, then by Username in ascending order
            bet.BetsByUsers.sort((a, b) => {
                if (b.TotalBetAmount !== a.TotalBetAmount) {
                    return b.TotalBetAmount - a.TotalBetAmount;
                }
                return a.Username.localeCompare(b.Username);
            });

            x.Bets.push(bet);
        }

        // Sort Bets by TotalBetAmount in descending order
        x.Bets.sort((a, b) => b.TotalBetAmount - a.TotalBetAmount);
        vm.push(x);
    }

    var obj = {
        IsSingleUserReport: nameFilter ? true : false,
        AllBetsTotalDonation: allBets.reduce((sum, q) => sum + q.TotalBetAmount, 0),
        AllBetsCnt: allBets.length,
        UserPosition: 0,
        TotalDonators: model.Summary.TotalUsers,
        ByBetType: vm
    };

    if (nameFilter) {
        obj.UserPosition = model?.UserRanking.find(q => q.Slug === nameFilter)?.Position || 0;
        obj.Username = model?.UserRanking.find(q => q.Slug === nameFilter)?.Username || null;

    }

    // console.log(vm);
    return obj;
}
