<template>
    <div class="container mt-3 mb-5">
        <div class="row">
            <div class="col-md-8 offset-md-2">

                <DataLoader>
                    <template v-slot:default="{ model }">
                        <div v-if="model">
                            <h1 class="text-center">Rangliste</h1>
                            Hier siehst du, wer aktuell wie viel Geld spendet. Über einen Klick auf das jeweilige Symbol
                            kannst du dir die Wetten der teilnehmenden anschauen.
                            <br><br>
                            <UserRanking :model="model" :userToHighlight="selectedSlug"></UserRanking>
                        </div>
                    </template>
                </DataLoader>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';
import DataLoader from "../components/DataLoader.vue";
import UserRanking from "../components/UserRanking.vue";
//import { toast } from 'vue3-toastify';
export default {
    components: { DataLoader, UserRanking },
    setup() {
        const route = useRoute();
        const userParam = route.params.userParameter;
        return { userParam };
    },
    data() {
        return {
            selectedSlug: ''
        };
    },
    async mounted() {
        let slugFromStorage = localStorage.getItem('selectedSlug');
        if (slugFromStorage)
            this.selectedSlug = slugFromStorage;
    }
}
</script>

<style></style>