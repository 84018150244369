<template>
    <div id="chart" class="pt-5">
        <apexchart type="bar" :options="computedChartOptions" :series="computedSeries"  style="color:black!important; border: 2px solid #333; border-radius: 8px; padding: 10px;">
        </apexchart>
    </div>

</template>

<script>
export default {
    name: 'ChartComponent',
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        computedChartOptions() {
            return {
                chart: {
                    // height: 350,
                    // width: 800,
                    type: 'line',
                    id: 'linechart-2',
                    zoom: { enabled: false },
                    toolbar: { show: false }
                },
                responsive: [
                    {
                        breakpoint: 1600, // Large desktops and laptops
                        options: {
                            chart: {
                                width: '100%',  // Wider but still centered
                                height: '500px'  // Adjust height to be more appropriate
                            },
                            title: {
                                style: { fontSize: '20px' }  // Slightly larger font for large screens
                            }
                        }
                    },
                    {
                        breakpoint: 1440, // Standard laptops and desktops
                        options: {
                            chart: {
                                width: '100%',  // Wider but still centered
                                height: '450px'  // Adjust height to fit better on laptop screens
                            },
                            title: {
                                style: { fontSize: '18px' }
                            }
                        }
                    },
                    {
                        breakpoint: 1024, // Tablets and smaller laptops
                        options: {
                            chart: {
                                width: '100%',
                                height: '400px'
                            },
                            title: {
                                style: { fontSize: '16px' }
                            }
                        }
                    },
                    {
                        breakpoint: 768, // Large phones and tablets
                        options: {
                            chart: {
                                width: '100%',
                                height: '300px'
                            },
                            title: {
                                style: { fontSize: '14px' }
                            }
                        }
                    },
                    {
                        breakpoint: 480, // Phones
                        options: {
                            chart: {
                                width: '100%',
                                height: '250px'
                            },
                            title: {
                                style: { fontSize: '12px' }
                            }
                        }
                    }
                ],

                annotations: {
                    //points: this.pointAnnotations,
                },
                theme: {
                    mode: 'light',
                    palette: 'palette1'

                },
                xaxis: {
                    type: 'category',
                    title: { text: 'Spieltag' }
                },
                yaxis: { title: { text: 'Betrag' }, labels: {
                        formatter: function (value) {
                            return value.toFixed(2).replace(".",",") + " €"; // Format y-axis labels to 2 decimal places
                        }
                    } },
                dataLabels: { enabled: false },
                stroke: { curve: 'straight' },
                grid: { padding: { right: 20, left: 10 } },
                title: {
                    text: 'Saisonspenden pro Spieltag',
                    offsetY: 10,
                    align: 'center',
                    style: { fontSize: '18px', fontFamily: 'Arial' }
                },
                tooltip: {
                    custom: function ({ seriesIndex, dataPointIndex, w }) {
                        var data = w.config.series[seriesIndex].data[dataPointIndex];
                        var tooltipHeader = "Spieltag " + data.x + "</small><br>" + data.description + "<br>Betrag: <b>" + data.y.toFixed("2") + " €</b>";
                        return "<div style='padding:5px;font-family:Arial;'>" + tooltipHeader + "</div>";
                    }
                }
            };
        },
        computedSeries() {

            var myData = this.model.map(item => ({
                x: "" + item.md, // or new Date(item.date).getTime() if using dates
                y: item.amountOfMd,
                description: item.mdOpp
            }));

            console.log(myData);
            return [
                {
                    data: myData,
                    name: "Betrag",
                },
            ];
        }
    },
}
</script>

<style scoped></style>