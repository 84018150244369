<script>
import { ref } from 'vue';
//HomePage.vue
import DataLoader from "../components/DataLoader.vue";
import PopularBets from "../components/PopularBets.vue";
import DonationSums from "../components/DonationSums.vue";
import TopDonators from "../components/TopDonators.vue";
import MostProfitBets from "../components/MostProfitBets.vue";
import InfoCard from "../components/InfoCard.vue";
import AllBets from "../components/AllBets.vue";

//import PleaseDonateNow from "../components/infoboxes/PleaseDonateNow.vue";
//import LastSeasonSettled from "../components/infoboxes/LastSeasonSettled.vue";


export default {
  components: {
    DataLoader, PopularBets, DonationSums, TopDonators, MostProfitBets, InfoCard, AllBets//PleaseDonateNow, LastSeasonSettled
  },
  setup() {
    const isLoading = ref(true);
    return {
      isLoading
    };
  },
  data() {
    return {
      showChart: false
    }
  },
  methods: {
    hasDonations(model) {
      return model.Summary.TotalDonation != '0';
    },
    hasBets(model) {
      return model.Summary.TotalBetCnt != '0';
    }
  }
};
</script>


<template>
  <div class="container mt-3">
    <div class="d-flex justify-content-center">
      <div class="h1">
        <span data-aos="fade-up" data-aos-delay="500" data-aos-anchor-placement="center-center">Hertha gucken</span><br>
        <span data-aos="fade-left" data-aos-delay="1000" data-aos-anchor-placement="center-center">
          und Gutes dabei tun <font-awesome-icon icon="heart" class="fa-beat c-blue"></font-awesome-icon>
          <!--<span class="badge bg-success ml-2" style="font-size:12px!important;margin-left:14px;">Beta</span>-->
        </span>
      </div>
    </div>

    <div data-aos-delay="1500" data-aos="fade-in" class="mb-5">
      <DataLoader>
        <template v-slot:default="{ model }">
          <div v-if="model">
            <div class="row">
              <template v-if="this.hasDonations(model)">
                <DonationSums :model="model" />
              </template>
            </div>

          
            <div class="row mt-4" v-if="!this.hasDonations(model)">
              <div class="col-12">
                <InfoCard></InfoCard>
              </div>
            </div>

            <div class="row" v-if="this.hasDonations(model)">
              <div class="col-lg-6 mt-5">
                <InfoCard></InfoCard>
              </div>
              <div class="col-lg-6 mt-5">
                <TopDonators :model="model" />
              </div>
              <div class="col-lg-6 mt-5">
                <MostProfitBets :model="model" />
              </div>
              <div class="col-lg-6 mt-5">
                <PopularBets :model="model" />
              </div>
            </div>

            <div class="row mt-5" v-if="this.hasBets(model)">
              <AllBets :model="model" />
            </div>
          </div>
        </template>
      </DataLoader>
    </div>
  </div>
</template>