<template>
    <router-view></router-view>
    <PushNotificationButton></PushNotificationButton>
</template>
<script>
import PushNotificationButton from "./components/PushNotificationButton.vue";
export default {
    components:{
        PushNotificationButton
    },
    async mounted() { }
}
</script>