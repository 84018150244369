<template>
    <div ref="popoverContent" style="display: none;">
        Lasse dich bei neuen Spendenständen & wichtigen Updates benachrichtigen
        <br>
        <a href="javascript:void(0)" class="btn btn-success my-3 popover-action">
            <font-awesome-icon icon="bell" class="fa-shake mx-1"></font-awesome-icon>
            Aktivieren
        </a>
    </div>

    <a href="javascript:void(0)" class="btn btn-info btn-lg my-3" ref="popoverButton" title="Benachrichtigungen aktivieren"
        data-bs-toggle="popover" style="position: fixed; bottom: 20px; right: 20px; z-index: 1000;">
        <font-awesome-icon icon="bell" class="fa-shake mx-1"></font-awesome-icon>
    </a>
</template>

<script>
import axios from 'axios';
import { toast } from 'vue3-toastify';
import { ref, onMounted } from 'vue';

export default {
    setup() {
        const popoverButton = ref(null);
        const popoverContent = ref(null);

        onMounted(() => {
            if (popoverButton.value && popoverContent.value) {
                new window.bootstrap.Popover(popoverButton.value, {
                    html: true,
                    content: popoverContent.value.innerHTML,
                });

                popoverButton.value.addEventListener('shown.bs.popover', () => {
                    const actionButton = document.querySelector('.popover .popover-action');
                    if (actionButton) {
                        actionButton.addEventListener('click', subscribeToNotifications);
                    }
                });
            }
        });

        const subscribeToNotifications = async () => {
            try {
                console.log('call subscribeToNotifications');
                const permission = await Notification.requestPermission();
                console.log('req permission res: ' + permission);

                if (permission !== 'granted') {
                    console.log('Notification permission denied');
                    return;
                }

                const registration = await navigator.serviceWorker.ready;
                console.log('3');
                const vapidKey = process.env.VUE_APP_PUBLIC_VAPID_KEY;
                console.log('4');

                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(vapidKey),
                });
                console.log('5');

                console.log('User is subscribed:', subscription);
                await sendSubscriptionToServer(subscription);
            } catch (error) {
                console.error('Failed to subscribe the user: ', error);
            }
        };

        const sendSubscriptionToServer = async (subscription) => {
            try {
                const enhancedSubscription = {
                    ...subscription.toJSON(),
                    subdomain: 'spenden',
                    username: localStorage.getItem('selectedSlug'),
                };
                const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/push/subscribe`, enhancedSubscription, {
                    headers: { 'Content-Type': 'application/json' },
                });

                if (response.status !== 200) throw new Error('Failed to send subscription to server');

                toast.success('Dein Gerät wurde erfolgreich für den Empang von Pushnachrichten registriert!');
            } catch (error) {
                console.error('Failed to send subscription:', error);
                toast.error('Fehler bei der Registrierung des Geräts für Pushnachrichten');
            }
        };

        const urlBase64ToUint8Array = (base64String) => {
            const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
            const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
            const rawData = window.atob(base64);
            return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
        };

        return {
            subscribeToNotifications,
            popoverButton,
            popoverContent,
        };
    },
};
</script>

<style>
/* Your styles here */
</style>
