<template>
    <span>{{ formattedValue }}&nbsp;{{ suffix }}</span>
  </template>
  
  <script>
  import { ref, onMounted, watch } from 'vue';
  
  export default {
    props: {
      targetValue: { type: Number, required: true },
      animationTime: { type: Number, default: 2000 },
      waitFor: { type: Number, default: 2000 },
      suffix: { type: String, default: null },
    },
    setup(props) {
      const currentValue = ref(0);
      const formattedValue = ref('0.00');
  
      onMounted(() => {
        setTimeout(() => {
          const increment = props.targetValue / (props.animationTime / 16);
          const timer = setInterval(() => {
            currentValue.value += increment;
            if (currentValue.value >= props.targetValue) {
              currentValue.value = props.targetValue;
              clearInterval(timer);
            }
            formattedValue.value = formatNumber(currentValue.value);
          }, 16);
        }, props.waitFor);
      });
  
      watch(() => props.targetValue, () => {
        currentValue.value = 0;
        formattedValue.value = '0.00';
      });
  
      function formatNumber(num) {
        return num.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
  
      return { formattedValue }; // Only return formattedValue
    }
  };
  </script>
  