<template>
    <CardComponent headline="Hertha BASE Saisonspenden" subheadline="">
        Hi, und herzlich willkommen, liebe Herthanerinnen und Herthaner!<br>
        Hier kannst du Wetten mit Hertha-Bezug abschließen, bei dessen Eintreten du dich dazu bereit erklärst, den
        Betrag zum Saisonende zu spenden.
        <br><br>
        So kannst du z.B. 1&nbsp;€ pro Heimsieg, 5&nbsp;€ auf jeden Assist von Marten Winkler, oder 50&nbsp;€ im Falle
        eines Aufstiegs spenden. Der Phantasie sind keine Grenzen gesetzt.
        <br><br>
        Der Zwischenstand wird wöchentlich für dich aktualisiert. Du musst dich also nicht selbst um eine Auswertung deiner
        Wetten kümmern. Zur Saison 24/25 wird die Spendensumme durch drei geteilt und zu gleichen Teilen
        an die folgenden Institutionen gespendet:
        <template v-if="!currentSeason">
            <b>//coming soon...</b>
        </template>
        <DonationTargetAccordion :season="currentSeason"></DonationTargetAccordion>
        <br>
        <a href="/mitmachen">Jetzt mitmachen!</a>
    </CardComponent>
</template>
<script>
import CardComponent from "../components/core/CardComponent.vue";
import seasons from '../assets/seasons.json';
import DonationTargetAccordion from "../components/core/DonationTargetAccordion.vue"


export default {
    components: { CardComponent,DonationTargetAccordion },
    data() {
        return { currentSeason: seasons.filter(q => q.isCurrentSeason)[0] }
    }
}
</script>