<template>
    <div class="container mt-3">
        <h1 class="text-center">Meine Wetten</h1>

        <DataLoader>
            <template v-slot:default="{ model }">
                <div v-if="model && updateModel(model)">
                    <div v-if="selectedSlug == '0'">Bitte wähle deinen Namen aus. Im Anschluss wird dir deine persönliche Wettübersicht angezeigt. Deine Auswahl wird gespeichert, sodass du beim nächsten Besuch der Seite automatisch auf deiner Wettübersicht landest.</div>
                    <select class="form-select form-select-lg mb-4 mt-3" v-model="selectedSlug"
                        @change="saveSelectedSlug">
                        <option value="0">Usernamen auswählen...</option>
                        <option v-for="user in UserList" :key="user.Slug" :value="user.Slug">
                            {{ user.Username }}
                        </option>
                    </select>
                    <template v-if="selectedSlug && selectedSlug != '0'">
                        <span> <font-awesome-icon icon="lightbulb" class="text-warning"></font-awesome-icon> Über <a :href="`/user/${selectedSlug}`">diesen Link</a> kannst du deine persönliche Spendenübersicht teilen.</span><br><br>
                        <AllBets :model="model" :username-slug="selectedSlug"></AllBets>
                    </template>
                </div>
            </template>
        </DataLoader>
    </div>
</template>

<script>
import DataLoader from "../components/DataLoader.vue";
import AllBets from "../components/AllBets.vue";
//import { toast } from 'vue3-toastify';

export default {
    components: { DataLoader, AllBets },
    async mounted() {
        //toast.info("Mounted -> Meine Wetten");
        let slugFromStorage = localStorage.getItem('selectedSlug');
        if (slugFromStorage)
            this.selectedSlug = slugFromStorage;

    },
    data() {
        return {
            model: null,
            selectedSlug: null // Initialize selectedSlug data property
        };
    },
    methods: {
        updateModel(model) {
            if (this.model !== model) {
                this.model = model;
            }
            return true;
        },
        saveSelectedSlug() {
            localStorage.setItem('selectedSlug', this.selectedSlug);
        }
    },
    computed: {
        UserList() {
            if (!this.model)
                return [];
            //console.log(this.model.UserRanking);
            let vm = this.model.UserRanking
                .slice() // Create a shallow copy to avoid mutating the original array
                .sort((a, b) => a.Username.localeCompare(b.Username)) // Sort by Username
                .map(user => {
                    return {
                        Username: user.Username,
                        Slug: user.Slug
                    };
                });
            return vm;
        }
    }
}
</script>