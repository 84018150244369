<template v-if="donationStats">
    <div class="col-lg-6 offset-lg-3">

        <CardComponent headline="Aktueller Spendenstand<br>Saison 2024/2025" :subheadline="subheadlineStr">
            <br>
            <div class="card-text bold">Insgesamt
                <CountUpDecimal :targetValue="donationStats.DonationAmount" :animationTime=1000 class="text-success"
                    suffix="€"></CountUpDecimal>
            </div>
            <div class="mb-3 small"><b>{{ donationStats.DonationBetCnt }}</b> Wetten
                <ShowDiff :diff="donationStats.DonationBetCntDiff" suffix="VJ"></ShowDiff>

                von <b>{{
            donationStats.DonationUsers }}</b> Personen
                <ShowDiff :diff="donationStats.DonationUsersDiff" suffix="VJ"></ShowDiff>

            </div>
            <template v-for="donation in donationStats.DonationsByType" :key="donation.BetTypeId">
                <div class="d-flex  justify-content-between">
                    <span><small class="bold">{{ donation.BetCnt }}x</small>


                        {{ donation.BetTypeStr }} </span>
                    <CountUpDecimal :targetValue="donation.TotalDonation" :animationTime=1000 class="text-success"
                        suffix="€" />
                </div>
                <hr v-if="donation !== donationStats.DonationsByType.slice(-1)[0]"
                    style="margin-top:6px;margin-bottom: 6px;">
            </template>
            <div class="mt-3"><a href="/mitmachen" class="card-link">Jetzt mitmachen</a>

                <a href="javascript:void(0)" @click="showChartAndScroll" class="card-link">Spenden pro Spieltag</a>
                &nbsp;<span class="badge bg-success" style="font-size:12px!important;">Neu</span>
            </div>
        </CardComponent>
    </div>

    <div v-if="model && this.showChart" id="chartplaceholder" class="col-12">
        <ChartComponent :model="model.Stats"></ChartComponent>
    </div>


</template>
<span v-else>Lade Daten...</span>

<script>
import CountUpDecimal from './core/CountUpDecimal.vue';
import ShowDiff from './core/ShowDiff.vue';
import CardComponent from "../components/core/CardComponent.vue";
import ChartComponent from "../components/Chart.vue";
import seasons from '../assets/seasons.json';
export default {
    name: 'DonationSums',
    props: {
        model: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showChart: false
        }
    },
    computed: {
        subheadlineStr() {
            return `Vom ${this.donationStats.LastUpdate}`;
        },

        donationStats() {
            //console.log(this.model);
            let r = {
                DonationAmount: this.model.Summary.TotalDonation,
                DonationUsers: this.model.Summary.TotalUsers,
                DonationBetCnt: this.model.Summary.TotalBetCnt,
                LastUpdate: this.model.Summary.LastUpdate,
                DonationsByType: [],
            };

            let previousSeason = seasons.filter(q => !q.isCurrentSeason)[0];
            r.DonationUsersDiff = previousSeason ? r.DonationUsers - previousSeason.donatorsCnt : null;
            r.DonationBetCntDiff = previousSeason ? r.DonationBetCnt - previousSeason.betsCnt : null;

            for (let i = 0; i < this.model.SummaryByBetType.length; i++) {
                r.DonationsByType.push(this.model.SummaryByBetType[i]);
            }
            return r;
        },
    },
    methods: {
        showChartAndScroll() {
            this.showChart = true;

            this.$nextTick(() => {
                const chartDiv = document.getElementById('chart');
                if (chartDiv) {
                    chartDiv.scrollIntoView({ behavior: 'smooth' });
                }
            });
        },
    },
    components: { CountUpDecimal, CardComponent, ShowDiff, ChartComponent }
};
</script>

<style scoped>
/* Add any styles you need here */
</style>