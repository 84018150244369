<template>
    <div class="container mt-3">
        <h3 class="text-center">Mitmachen</h3>
        <div class="row">
            <div class="col-lg-6 mt-3 mb-3">
                <CardComponent headline="So funktioniert's" subheadline="Hey, das geht ab">
                    <span>
                        Schön, dass du dich dazu entschlossen hast mitzumachen!<br>Es ist nie zu spät: Du
                        kannst <b>die gesamte Saison über</b> Wetten mit Hertha-Bezug abschließen, bei dessen Eintreten
                        du dich dazu bereit erklärst, den
                        Betrag zum Saisonende zu spenden.
                        <br><br>
                        So kannst du z.B. 1&nbsp;€ pro Heimsieg, 5&nbsp;€ auf jeden Assist von Marten Winkler, oder
                        50&nbsp;€ im Falle
                        eines Aufstiegs spenden. Der Phantasie sind keine Grenzen gesetzt.
                        <br><br>
                        Der Zwischenstand wird wöchentlich für dich aktualisiert. Du musst dich also nicht selbst um
                        eine Auswertung deiner
                        Wetten kümmern.
                        <div>
                            <a href="#jetzt-wetten" class="btn btn-info my-3" @click.prevent="highlightDiv">Wetten
                                einreichen</a>
                        </div>

                    </span>
                </CardComponent>
            </div>
            <div class="col-lg-6 mt-3 mb-3" v-if="currentSeason">
                <CardComponent headline="Unsere Spendenziele" :subheadline="currentSeason.seasonName">
                    Zum Ende der Saison wird die erzielte Spendensumme eingesammelt und anschließend in drei gleichen
                    Teilen an die folgenden Organisationen gespendet:
                    <DonationTargetAccordion :season="currentSeason"></DonationTargetAccordion>
                </CardComponent>
            </div>
        </div>

        <div id="jetzt-wetten" class="p-2" ref="jetztWettenDiv">
            <h4 class="my-3">Wetten einreichen</h4>
            <span>Um es dir so einfach wie möglich zu machen, beantworte einfach die folgende Frage:</span>
            <br><br>
            <template v-if="isDiscordMember === null">
                <h5>Bist du Mitglied des Hertha BASE Discord-Servers?</h5>
            </template>
            <template v-if="isDiscordMember == true">
                <h4>Poste deine Wette(n) bei Discord</h4>
            </template>
            <template v-if="isDiscordMember == false">
                <h4>Discord beitreten, oder Formular ausfüllen</h4>
            </template>

            <div>
                <br>
                <template v-if="isDiscordMember === null">
                    <button class="btn btn-info" style="min-width:60px;" @click="isDiscordMember = true">Ja</button>
                    <button class="btn btn-info mx-3" style="min-width:60px;"
                        @click="isDiscordMember = false">Nein</button>
                </template>
                <template v-if="isDiscordMember">
                    Das ist super! 😊<br>
                    Poste deine Wette(n) einfach im Kanal <a
                        href="https://discord.com/channels/882900065071554591/1003694801083957330">#saisonwette</a>. Sie
                    wird daraufhin zeitnah von mir im System eingetragen und unter <a href="/meine-wetten">Meine
                        Wetten</a>
                    zu finden sein.
                </template>
                <template v-if="isDiscordMember === false">
                    Der Discord-Server von Hertha BASE ist ein Chat, wo du dich mit gleichgesinnten austauschen kannst
                    (und
                    u.a. auch Saisonwetten gepostet werden können). <br>Klingt das interessant für dich? <a
                        href="javascript:void(0)" @click="showDiscordInstructions = true" class="bold">Zur Discord
                        Anleitung</a><br><br>
                    <template v-if="showDiscordInstructions">
                        <h4>Anleitung</h4>
                        <ul>
                            <li>Lade dir Discord <a href="https://discord.com" target="_blank">herunter</a> (verfügbar
                                für
                                Windows, MacOS, iOS & Android)</li>
                            <li>Installiere und starte Discord. Beim Start wirst du aufgefordert einen Account zu
                                erstellen
                            </li>
                            <li>Trete über <a href="https://discord.gg/CyzqXTN3Yp">diesen Link</a> dem Server bei</li>
                            <li>Geschafft! Du bist nun auf dem Hertha BASE Discord-Server</li>
                            <li>Nun kannst du in <a
                                    href="https://discord.com/channels/882900065071554591/1003694801083957330">#saisonwette</a>
                                deine Wette(n) posten</li>
                        </ul>
                    </template>
                    Falls du dem Discord-Server nicht beitreten möchtest, <b>kein Problem</b>!<br>
                    Fülle einfach das untenstehende Formular aus. Falls du bei der Formulierung der Wetten Hilfe
                    benötigst,
                    besuche die
                    <a href="/hilfe">Hilfe-Seite</a>.

                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSdWtEmm0U9Gv4seXPNzHvWPm5kvGF41Z68_txPdXTGp-dHHVg/viewform?embedded=true"
                        style="width: 100%; height: 100vh; border: none; margin-top: 24px;" frameborder="0"
                        marginheight="0" marginwidth="0">

                        Wird geladen…</iframe>
                </template>
            </div>
        </div>


    </div>
</template>

<script>
import DonationTargetAccordion from "../components/core/DonationTargetAccordion.vue";
import seasons from '../assets/seasons.json';
import CardComponent from "../components/core/CardComponent.vue";
export default {
    data() {
        return {
            isDiscordMember: null,
            showDiscordInstructions: false,
            seasons,
            currentSeason: seasons.filter(q => q.isCurrentSeason)[0]
        };
    },
    methods: {
        highlightDiv() {
            const targetDiv = this.$refs.jetztWettenDiv;

            // Scroll to the target div
            targetDiv.scrollIntoView({ behavior: 'smooth' });

            // Add the highlight class
            targetDiv.classList.add('highlighted');
           
            // Remove the highlight class after 1 second
           setTimeout(() => {
                targetDiv.classList.remove('highlighted');
            }, 1000);
        },
    },
    components: { DonationTargetAccordion, CardComponent },
    async mounted() {
        //toast.info("Mounted -> Meine Wetten");
    }
}
</script>
<style></style>
