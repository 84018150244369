<template v-if="profitableBets">
    <CardComponent headline="Profitabelste Wetten" subheadline="">
        <div class="mb-3">
            Welche Wetten haben bislang am meisten Geld eingespielt?
        </div>
        <template v-for="(profitableBet) in profitableBets" :key="profitableBet.BetName">
            <div class="d-flex justify-content-between">
                <span><small class="bold">{{ profitableBet.Cnt }} Wetten</small> {{ profitableBet.BetName }} ({{
            profitableBet.Occured }}x)</span>
                <CountUpDecimal :targetValue="profitableBet.TotalAmount" :animationTime=1000 class="text-success"
                    suffix="€" />
            </div>
            <hr v-if="profitableBet !== profitableBets.slice(-1)[0]" style="margin-top:6px;margin-bottom: 6px;" />
        </template>
    </CardComponent>
</template>
<span v-else>Lade Daten...</span>
<script>
import CountUpDecimal from './core/CountUpDecimal.vue';
import CardComponent from "../components/core/CardComponent.vue";

export default {
    name: 'MostProfitBets',
    props: {
        model: {
            type: Object,
            required: true,
        },
    },
    computed: {
        profitableBets() {
            // Exclude bets with BetTypeId == 4
            const filteredBets = this.model.AllBets.filter(bet => bet.BetTypeId !== 4);

            // Group bets by BetStr
            const betGroups = Object.values(filteredBets.reduce((groups, bet) => {
                groups[bet.BetStr] = groups[bet.BetStr] || [];
                groups[bet.BetStr].push(bet);
                return groups;
            }, {}));

            // Map group data to desired format and include totalBetAmount
            const resultSet = betGroups.map(group => {
                const totalBetAmount = group.reduce((sum, bet) => sum + bet.TotalBetAmount, 0);
                return {
                    BetName: group[0].BetStr,
                    Cnt: group.length,
                    TotalAmount: totalBetAmount,
                    Occured: group[0].Occured
                };
            });

            // Sort by totalBetAmount in descending order and take the top 5
            const topBetGroups = resultSet.sort((a, b) => b.TotalAmount - a.TotalAmount).slice(0, 5);

            //console.log(topBetGroups);
            return topBetGroups;
        }
    },
    components: { CountUpDecimal, CardComponent }
};
</script>

<style scoped>
/* Add any styles you need here */
</style>