<template>
    <template v-if="topDonators">
        <CardComponent headline="Großspenden" subheadline="">
            <div class="card-text mb-3">Generös und mit tiefen Taschen ausgestattet wie einst Lars Windhorst sind die
                folgenden Damen und
                Herren:
            </div>
            <template v-for="(donator, index) in topDonators" :key="donator.Username">
                <div class="d-flex justify-content-between">
                    <span><b>{{ index + 1 }}.</b> <a :href="`/user/${donator.Slug}`">{{ donator.Username }}</a> </span>
                    <CountUpDecimal :targetValue="donator.TotalAmount" :animationTime=1000 class="text-success"
                        suffix="€" />
                </div>
                <hr v-if="donator !== topDonators.slice(-1)[0]" style="margin-top:6px;margin-bottom: 6px;" />
            </template>
            <div class="mt-3"><a href="/rangliste" class="card-link">Alle anzeigen</a>
            </div>
        </CardComponent>
    </template>
    <span v-else>Lade Daten...</span>
</template>

<script>
import CountUpDecimal from './core/CountUpDecimal.vue';
import CardComponent from "../components/core/CardComponent.vue";
export default {
    name: 'TopDonators',
    props: {
        model: {
            type: Object,
            required: true,
        },
    },
    computed: {
        topDonators() {
            if (this.model.UserRanking.length > 10)
                return this.model.UserRanking.slice(0, 10);
            return this.model.UserRanking;
        },
    },
    components: { CountUpDecimal, CardComponent }
};
</script>

<style scoped>
/* Add any styles you need here */
</style>