// fontawesome.js
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Import the specific icons you want to use
import { faHeart, faUser, faEnvelope, faBars, faLink,faArrowUpRightFromSquare, faLightbulb, faExclamation, faImage, faInfo, faInfoCircle, faBell, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

// Add icons to the library
library.add(faHeart, faUser, faEnvelope, faBars, faLink, faArrowUpRightFromSquare, faLightbulb, faExclamation, faImage, faInfo, faInfoCircle, faBell, faCaretUp, faCaretDown);

export { FontAwesomeIcon };

//<font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" />
