<template>
    <!-- Only render if `diff` is defined and not null -->
   
        <template v-if="diff !== null && diff !== undefined && diff >= 0">
            <span class="text-success">
                
                    <font-awesome-icon icon="caret-up"></font-awesome-icon>    
                {{ diff }} {{suffix}}
            </span>
          
          
        </template>
        <template v-if="diff !== null && diff !== undefined && diff < 0">
            <span class="text-danger">
                
                    <font-awesome-icon icon="caret-down"></font-awesome-icon>    
                {{ diff*-1 }} {{suffix}}
                </span>
        </template>


</template>

<script>
export default {
    name: 'ShowDiff',
    props: {
        diff: {
            type: Number,
            required: true
        },
        suffix: {
            type: String,
            required: false
        }
    }
}
</script>

<style scoped>
/* Add your component-specific styles here */
</style>