//main.js
import './assets/custom.css';
import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router';
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import AOSPlugin from './aos-wrapper'; // Import your AOS wrapper
import { FontAwesomeIcon } from './fontawesome.js';
import './registerServiceWorker';
import VueApexCharts from "vue3-apexcharts";

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
const pinia = createPinia();
app.use(pinia);
app.use(router);
app.use(VueApexCharts);
app.use(Vue3Toastify, {
    autoClose: 5000,
    theme: "dark",
    "position": "top-right",
});
app.use(AOSPlugin);
app.mount('#app');