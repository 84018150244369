<template>
    <div class="container mt-3">
        <h1 class="text-center">Hilfe</h1>
        <div class="mt-4">Fragen und Antworten zu den Saisonspenden findest du hier. Solltest du noch offene Fragen
            haben, melde dich bitte im Kanal <a href="https://discord.com/channels/882900065071554591/1003694801083957330" target="_blank">#saisonspende</a> auf dem Hertha BASE Discord-Server, oder nutze das <a
                href="#kontakt">Kontaktformular</a>.</div>
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <div class="accordion mt-4" id="accordionExample">
                    <div v-for="(faq, index) in faqs" :key="index" class="accordion-item">
                        <h2 :id="'heading' + index" class="accordion-header">
                            <button class="accordion-button" :class="{ collapsed: index !== 0 }" type="button"
                                :data-bs-toggle="'collapse'" :data-bs-target="'#collapse' + index"
                                :aria-expanded="index === 0 ? 'true' : 'false'" :aria-controls="'collapse' + index">
                                {{ faq.question }}
                            </button>
                        </h2>
                        <div :id="'collapse' + index" :class="['accordion-collapse collapse', { show: index === 0 }]"
                            :aria-labelledby="'heading' + index" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <span v-html="faq.answer"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-3" id="kontakt">
                <div style="width: 100%; height: 100vh; overflow: hidden; margin-top: 24px;">
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSeW_S-5TYCYBCxgf_OsIdi7CLrQ2XfoJwuC-nNkRvv-ZB9TBA/viewform?embedded=true"
                        style="width: 100%; height: 100vh; border: none; margin-top: 24px;" frameborder="0"
                        marginheight="0" marginwidth="0">Wird geladen…</iframe>
                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
    </div>

</template>
<script>
export default {
    data() {
        return {
            faqs: [
                {
                    question: 'Was kann ich wetten?', answer: 'Prinzipiell kannst du auf alles wetten, was einen Hertha-Bezug hat. Wetten lassen sich in drei verschiedene Kategorien einteilen: ' +
                        '<br><br><b>1. Spieltagswetten</b><br><br>Hierbei handelt es sich um Wetten, die jeden Spieltag eintreffen können. z.B.: <ul><li>X € pro Hertha-Tor</li><li>X € pro Scorer Tabakovic</li><li>X € pro Einsatz Kevin Sessa</li></ul>' +
                        '<br><b>2. Saisonwetten</b><br><br>Wetten, die erst zum Saisonende ausgewertet werden können. z.B.:<ul><li>X € Beim Aufstieg</li><li>X € wenn Tabakovic Torschützenkönig wird</li><li>X € bei mehr als 60 erzielten Toren</li></ul>' +
                        '<br><b>3. Einzelspielwetten</b><br><br>Spontane Wetten, die sich auf den kommenden Spieltag, oder zum Teil sogar auf das laufende Spiel beziehen. z.B.: <ul><li>X € wenn Hertha gegen FC Köln gewinnt</li><li>X € wenn Dudziak noch eingewechselt wird</li><li>X € wenn Tabakovic heute trifft</li></ul>In welche der drei Kategorien deine Wetten fallen, musst du <b>nicht</b> selbst angeben - sie werden automatisch für dich kategorisiert. Es dient lediglich der Veranschaulichung in welcher Art Wetten abgeschlossen werden können.<br><br>Wie du deine Wetten abgeben kannst, erfährst du unter <a href="/mitmachen">mitmachen</a>.'
                },
                { question: 'Wann muss ich meine Wetten abgegeben haben?', answer: 'Prinzipiell kannst du die gesamte Saison über Wetten angeben. Späteinsteiger sind also ausdrücklich willkommen. Bitte beachte allerdings, dass bei spieltagsbezogenen Wetten die bereits gespielten Partien rückwirkend in die Berechnung mit einfließen.<br><br>Durch eine Anpassung der Spendensumme pro Eintreten des Ereignisses kannst du jedoch gut beeinflussen, welche Auswirkungen die rückwirkende Erfassung auf dich hat.' },
                { question: 'Muss ich meine Wetten selbst pflegen?', answer: 'Nein! Deine Wetten werden für dich ausgewertet und sogar wöchentlich aktualisiert. Unter <a href="/meine-wetten">meine Wetten</a> siehst du immer deinen aktuellen Spendenstand.<br><br>Ab und an kann mal ein Flüchtigkeitsfehler unterlaufen. In diesem Fall, schreibe bitte in <a href="https://discord.com/channels/882900065071554591/1003694801083957330" target="_blank">#saisonspende</a> auf dem Discord-Server, oder nutze das <a href="#kontakt">Kontaktformular</a>' },
                { question: 'Für welche Spiele gelten die Wetten?', answer: 'Bei der Auswertung werden alle <b>Pflichtspiele</b> berücksichtigt. Also 2. Bundesliga und DFB-Pokal. Bitte habe Verständnis dafür, dass es aus organisatorischen Gründen nicht möglich ist, die Pokalspiele auszuschließen und Freundschaftsspiele nicht gewertet werden.' },
                { question: 'Wohin wird das Geld gespendet?', answer: 'Jedes Jahr werden neue Spendenziele festgelegt. Für die <b>Saison 2024/2025</b> handelt es sich um die folgenden Organisationen: <br><br><ul><li><a href="https://verhaltenssucht-berlin.de/" target="_blank">Café Beispiellos</a></li><li><a href="https://www.seitenwechsel-berlin.de/verein" target="_blank">Seitenwechsel e.V.</a></li><li><a href="https://www.championsohnegrenzen.com/" target="_blank">CHAMPIONS ohne GRENZEN e.V.</a></li></ul>Die Gesamtspendensumme wird in gleichen Teilen auf die jeweiligen Organisationen verteilt.' },
                { question: 'Wann muss ich das Geld spenden?', answer: 'Jedes Jahr in der Sommerpause werden alle Spenden durch @flixius geprüft und final ausgewertet. Daraufhin kannst du <a href="/meine-wetten">deine Wetten</a> kontrollieren, ggf. Korrekturen anfordern und auf Wunsch deinen Betrag aufrunden.<br><br>Im Anschluss wird bekanntgegeben, wohin du deine Spende schicken sollst. Du wirst es auf keinen Fall verpassen, da es auf allen Kanälen, die HerthaBase zur Verfügung stehen, angekündigt wird.' },
                { question: 'Kommen auch 100% meiner Spende an?', answer: 'Wir versuchen die Kosten so gering wie möglich zu halten und verdienen keinen Cent an diesem Projekt.<br><br>Ab der Saison 24/25 werden aus den Spendeneinnahmen Serverkosten in Höhe von 180&nbsp;€ pro Jahr gedeckt. Dieser Betrag wird benötigt, um diese Seite zu unterhalten und das <a href="https://stimmung.herthabase.de" target="_blank">Stimmungsbarometer</a> zu betreiben.<br><br>Anders als viele Spendenorganisationen erheben wir keine Gebühren, so dass über 95% deiner Spende auch tatsächlich bei den Organisationen landet.' },
                { question: 'Kriege ich eine Spendenquittung?', answer: 'Das ist möglich. Allerdings musst du dich, <b>bevor</b> du das Geld sendest bei @flixius in Discord melden. Falls du nicht bei Discord bist, fülle bitte das <a href="#kontakt">Kontaktformular</a> aus.' },
                { question: 'Ich möchte meine Wetten ändern', answer: 'Falls du nachträglich deine platzierten Wetten ändern möchtest, oder du einen Rechenfehler entdeckt hast, schreibe bitte im Discord-Kanal <a href="https://discord.com/channels/882900065071554591/1003694801083957330" target="_blank">#saisonspende</a>, oder nutze das <a href="#kontakt">Kontaktformular</a>.' }
            ]
        };
    }
};
</script>