<template>
    <div class="mb-4">
        <h3 v-if="BetsReport.Username">Spendenübersicht <span class="c-blue">{{ BetsReport.Username }}</span> </h3>
        <h1 v-else>
            Wettübersicht
        </h1>

        <div v-if="BetsReport.UserPosition > 0">
            Platzierung: <b>{{ BetsReport.UserPosition }}</b> von <b>{{ BetsReport.TotalDonators }}</b>
        </div>
        Insgesamt: <b>{{ formatNumber(BetsReport.AllBetsTotalDonation) }} &euro;</b><br>
        Anzahl Wetten: <b>{{ BetsReport.AllBetsCnt }}</b>
        <div v-if="!BetsReport.IsSingleUserReport">Teilnehmerinnen: <b>{{ BetsReport.TotalDonators }}</b></div>
    </div>


    <span class="text-muted my-2" v-if="hasMultipleBetTypes()">Wettart auswählen</span>


    <div v-if="hasMultipleBetTypes()" class="btn-group w-100 mb-3" role="group"
        aria-label="Basic radio toggle button group">
        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" value="0"
            v-model="selectedBetType">
        <label class="btn btn-outline-primary" for="btnradio1">Alle ({{ getCntByBetType(0) }})</label>

        <template v-if="hasBetType(1)">
            <input type="radio" class="btn-check" name="btnradio" id="btnradio2" value="1" autocomplete="off"
                v-model="selectedBetType">
            <label class="btn btn-outline-primary" for="btnradio2">Spieltag ({{ getCntByBetType(1) }})</label>
        </template>

        <template v-if="hasBetType(2)">
            <input type="radio" class="btn-check" name="btnradio" id="btnradio3" value="2" autocomplete="off"
                v-model="selectedBetType">
            <label class="btn btn-outline-primary" for="btnradio3">Saison ({{ getCntByBetType(2) }})</label>
        </template>

        <template v-if="hasBetType(3)">
            <input type="radio" class="btn-check" name="btnradio" id="btnradio4" value="3" autocomplete="off"
                v-model="selectedBetType">
            <label class="btn btn-outline-primary" for="btnradio4">Einzelspiel ({{ getCntByBetType(3) }})</label>
        </template>

        <template v-if="hasBetType(4)">
            <input type="radio" class="btn-check" name="btnradio" id="btnradio5" value="4" autocomplete="off"
                v-model="selectedBetType">
            <label class="btn btn-outline-primary" for="btnradio5">Aufrundung ({{ getCntByBetType(4) }})</label>
        </template>
    </div>
    <div v-if="!BetsReport.IsSingleUserReport" class="form-check form-switch" style="margin-left:12px;">
        <input class="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckChecked"
            v-model="showUserBetDetails" />
        <label class="form-check-label cursor-pointer bold" style="user-select: none;"
            for="flexSwitchCheckChecked">Aufschlüsselung nach wettenden</label>
    </div>
    <div class="form-check form-switch" style="margin-left:12px;">
        <input class="form-check-input cursor-pointer" type="checkbox" id="nullChecked" v-model="hideNullBets" />
        <label class="form-check-label cursor-pointer bold" style="user-select: none;" for="nullChecked">Noch nicht
            eingetretene ausblenden
        </label>
    </div>


    <div v-for="(betsByBetType, index) in BetsReport.ByBetType" :key="index" class="betyByTypeDiv mt-2"
        :data-bettype="betsByBetType.BetTypeId"
        v-show="selectedBetType == 0 || selectedBetType == betsByBetType.BetTypeId">

        <table class="table table-light table-striped table-hover table-borderless">
            <thead>
                <tr class="table-dark">
                    <th>
                        <small>{{ betsByBetType.SummaryByBetType.BetCnt }}x</small>
                        Wettart: {{ betsByBetType.SummaryByBetType.BetTypeStr }}

                    </th>
                    <th style="text-align:end;" class="text-success">
                        {{ formatNumber(betsByBetType.SummaryByBetType.TotalDonation) }}&nbsp;&euro;</th>
                </tr>
                <tr>
                    <th>Wette</th>
                    <th style="text-align:end;">Summe</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(bet, index) in betsByBetType.Bets" :key="index">
                    <tr v-if="!hideNullBets || (hideNullBets && bet.Occured > 0)">
                        <td>
                            <b v-if="!BetsReport.IsSingleUserReport && betsByBetType.BetTypeId !== '4'">{{ bet.Occured
                                }}x</b> {{ bet.BetName }}
                        </td>

                        <td style="text-align:end;">
                            <span v-if="BetsReport.IsSingleUserReport">
                                <b>{{ bet.Occured }}x</b>
                                {{ formatNumber(bet.BetsByUsers[0].AmountPerOccurence) }}&nbsp;&euro; ≙
                            </span>

                            <span class="text-success bold">{{ formatNumber(bet.TotalBetAmount) }}&nbsp;&euro;</span>
                        </td>
                    </tr>
                    <td v-if="showUserBetDetails && !BetsReport.IsSingleUserReport && (!hideNullBets || (hideNullBets && bet.Occured > 0))"
                        colspan="2">
                        <table class="table table-hover m-0">
                            <tbody>
                                <tr v-for="(betByUser, index) in bet.BetsByUsers" :key="index">
                                    <td>
                                        <div class="d-flex justify-content-between">
                                            <span>
                                                <a :href="`/user/${betByUser.Slug}`">
                                                    {{ betByUser.Username }}
                                                    <!-- <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" /> -->
                                                </a>
                                            </span>
                                            <span class="text-right float-right" style="text-align: end;">
                                                <template v-if="betsByBetType.BetTypeId !== '4'">
                                                    {{ bet.Occured }}x {{ formatNumber(betByUser.AmountPerOccurence) }}
                                                    &euro; ≙
                                                </template>
                                                <span class="text-success bold">{{
            formatNumber(betByUser.TotalBetAmount) }}
                                                    &euro;</span>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </template>
            </tbody>
        </table>
    </div>

    <!-- {{ BetsReport }}-->
</template>

<script>
import { GetBetsByFilteredData } from '../GetBetsReport';

export default {
    name: 'AllBets',
    props: {
        model: {
            type: Object,
            required: true,
        },
        usernameSlug: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            selectedBetType: 0,
            showUserBetDetails: false,
            hideNullBets: true
        };
    },
    mounted() {
        this.hideNullBets = !this.BetsReport.IsSingleUserReport;
    },
    computed: {
        BetsReport() {
            let vm = GetBetsByFilteredData(this.model, this.usernameSlug);
            return vm;
        }
    },
    methods: {
        hasBetType(betTypeId) {
            return this.BetsReport.ByBetType.some(bet => bet.BetTypeId === betTypeId.toString());
        },
        hasMultipleBetTypes() {
            return this.BetsReport.ByBetType.length > 1;
        },
        getCntByBetType(betTypeId) {
            if (betTypeId === 0)
                return this.BetsReport.ByBetType.reduce((total, bet) => {
                    return total + bet.Bets.reduce((sum, b) => sum + b.BetCnt, 0);
                }, 0);
            const bet = this.BetsReport.ByBetType.find(q => q.BetTypeId === betTypeId.toString());
            return bet ? bet.Bets.reduce((sum, b) => sum + b.BetCnt, 0) : 0;
        },
        formatNumber(num) {
            return num.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }

    },
    components: {}
};
</script>

<style scoped>
/* Add your styles here if needed */
</style>
