<template>
    <div class="card h-100 d-flex flex-column">
        <div class="card-body">
            <h5 class="card-title" v-html="headline"></h5>
            <h6 v-if="subheadline" class="card-subtitle text-muted" v-html="subheadline"></h6>
            <div class="card-text">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'CardComponent',
    props: {
        headline: {
            type: String,
            required: true,
        },
        subheadline: {
            type: String,
            required: false
        }
    }
}
</script>