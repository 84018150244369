<template>
    <div class="mb-3">
        <div>Teilnehmende: <b>{{ Ranking.length }}</b></div>
        <div v-if="RankingPositionOfUser > 0">
            Du befindest dich aktuell auf dem <b>{{ RankingPositionOfUser }}. Platz <font-awesome-icon icon="heart"
                    class="ms-2 fa-beat c-blue"></font-awesome-icon> </b>
        </div>
    </div>

    <table class="table table-light table-striped table-hover table-borderless">
        <thead>
            <tr class="table-dark">
                <th>Platzierung</th>
                <th>Username</th>
                <th style="text-align:end;">Spendensumme</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(usr, index) in Ranking" :key="index" :class="{ 'bg-highlight': usr.Slug === userToHighlight }">
                <td><b>{{ usr.Position }}.</b></td>
                <td>
                    <a :href="`/user/${usr.Slug}`">
                        <!-- <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" />-->
                        {{ usr.Username }}
                    </a>

                </td>
                <td style="text-align:end;" class="text-success bold">{{ formatNumber(usr.TotalAmount) }}&nbsp;&euro;
                </td>
            </tr>
        </tbody>
    </table>


</template>

<script>
export default {
    name: 'UserRanking',
    props: {
        model: {
            type: Object,
            required: true,
        },
        userToHighlight: {
            type: String,
            required: false
        }
    },
    data() {
        return {};
    },
    computed: {
        Ranking() {
            //console.log(this.model.UserRanking);
            return this.model.UserRanking;
        },
        RankingPositionOfUser() {
            if (!this.userToHighlight)
                return 0;
            let user = this.model.UserRanking.find(q => q.Slug === this.userToHighlight);
            let position = user ? user.Position : 0;
            return position;
        }
    },
    methods: {
        formatNumber(num) {
            return num.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    },
    components: {}
};
</script>

<style scoped>
/* Add your styles here if needed */
</style>
