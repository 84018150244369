//useBackendStore.js
import { defineStore } from 'pinia';
import axios from 'axios';

const EXP_IN_MIN = 30;
const EXPIRATION_TIME = EXP_IN_MIN * 60 * 1000; // 30 minutes in milliseconds

export const useBackendStore = defineStore('backendStore', {
  state: () => ({
    model: null,
    loading: false,
    error: null,
    lastFetched: null,
  }),
  actions: {
    async fetchModel() {
      //console.log('fetchModel()');
      const now = Date.now();

      if (this.model && now - this.lastFetched < EXPIRATION_TIME) return;

      this.loading = true;
      this.error = null;
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/spenden/get`);
        this.model = response.data;
        this.lastFetched = now;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
});
