// src/router.js 
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './pages/HomePage.vue';
import MyBetsPage from './pages/MyBetsPage.vue';
import FaqPage from './pages/FaqPage.vue';
import MitmachenPage from './pages/MitmachenPage.vue';
import UserPage from './pages/UserPage.vue';
import RankingPage from './pages/RankingPage.vue';
import ArchivPage from './pages/ArchivPage.vue';
import AOS from "aos";
import 'aos/dist/aos.css';

const routes = [
    { path: '/', component: HomePage },
    { path: '/meine-wetten', component: MyBetsPage },
    { path: '/mitmachen', component: MitmachenPage },
    { path: '/hilfe', component: FaqPage },
    { path: '/user/:userParameter', component: UserPage },
    { path: '/rangliste', component: RankingPage },
    { path: '/spendenhistorie', component: ArchivPage },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    AOS.init({duration: 1500}); // Initialize AOS
    next();
  });
  

export default router;