<template v-if="popularBets">
    <CardComponent headline="Beliebteste Wetten" subheadline="">
        <div class="mb-3">Hier siehst du die beliebtesten Wetten (<i>quantitativ</i>, also unabhängig der eingespielten
            Spendensumme)</div>

        <template v-for="(popularBet) in popularBets" :key="popularBet.BetName">
            <div class="d-flex justify-content-between">
                <span><b>{{ popularBet.Cnt }}x</b> {{ popularBet.BetName }} </span>
                <CountUpDecimal :targetValue="popularBet.TotalAmount" :animationTime=1000 class="text-success"
                    suffix="€" />
            </div>
            <hr v-if="popularBet !== popularBets.slice(-1)[0]" style="margin-top:6px;margin-bottom: 6px;" />
        </template>
    </CardComponent>
</template>
<span v-else>Lade Daten...</span>
<script>
import CountUpDecimal from './core/CountUpDecimal.vue';
import CardComponent from "../components/core/CardComponent.vue";
export default {
    name: 'PopularBets',
    props: {
        model: {
            type: Object,
            required: true,
        },
    },
    computed: {
        popularBets() {
            //exclude "Aufgerundet"
            const filteredBets = this.model.AllBets.filter(bet => bet.BetTypeId !== 4);

            const topBetGroups = Object.values(filteredBets.reduce((groups, bet) => {
                groups[bet.BetStr] = groups[bet.BetStr] || [];
                groups[bet.BetStr].push(bet);
                return groups;
            }, {})).sort((a, b) => b.length - a.length).slice(0, 5);

            // Map group data to desired format
            const resultSet = topBetGroups.map(group => {
                const totalBetAmount = group.reduce((sum, bet) => sum + bet.TotalBetAmount, 0);
                return {
                    BetName: group[0].BetStr,
                    Cnt: group.length,
                    TotalAmount: totalBetAmount,
                };
            });
            //console.log(resultSet);
            return resultSet;
        },

    },
    components: { CountUpDecimal, CardComponent }
};
</script>

<style scoped>
/* Add any styles you need here */
</style>